<template>
    <v-dialog
        v-model="dialog"
        scrollable
         max-width="1200px"
        transition="dialog-transition"
        persistent
    >
    <v-card>
        <v-toolbar color="secondary" dark>
            Información de la Sociedad Civil
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('cerrar')"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>    
        <v-card-text class="pt-5">
            <v-row v-if="!loading && sociedad">
                <v-col cols="12" lg="12" md="12" xs="12">
                    <v-row>
                        <v-col cols="6" class="pb-0">
                            <h5 class="grey--text">NOMBRE SOCIEDAD CIVIL:</h5>
                            <p class="body-2">{{sociedad.nombreSociedad}}</p>
                            <h5 class="grey--text">MATRICULA:</h5>
                            <p class="body-2">{{sociedad.matricula}}</p>
                            <h5 class="grey--text">ESTADO:</h5>
                            <p class="body-2">
                                <v-chip
                                    color="primary"
                                    text-color="white"
                                    small
                                >
                                    {{sociedad.estado}}
                                </v-chip>
                            </p>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <h5  class="grey--text">FECHA DE SOLICITUD:</h5>
                            <p class="body-2">{{formatDateText(sociedad.fechaCreacion)}}</p>
                            <h5  class="grey--text">NRO RESOLUCION:</h5>
                            <p class="body-2">{{sociedad.numeroResolucion}}</p>
                            <h5 class="grey--text">FECHA RESOLUCION:</h5>
                            <p class="body-2">{{formatDateText(sociedad.fechaResolucion)}}</p>
                            <p class="body-2 text-center">
                               <DescargarArchivo 
                                    v-if="sociedad.rutaArchivoSociedadCivil"
                                    :id="sociedad.id"
                                    :url="`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${sociedad.id}/archivo`"
                                    titulo="DOCUMENTO PRESENTADO"
                                >
                                <template slot="titulo-boton">
                                    DOCUMENTO PRESENTADO
                                </template>
                               </DescargarArchivo>
                            </p>
                        </v-col>
                    </v-row>    
                    <v-row v-if="cpt!=null && cpt.estado!='PAGADO'" >
                        <v-col cols="12" class="text-center">
                            <codigo-pago :cpt="cpt"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="qr!=null && qr.estado!='PAGADO'">
                        <v-col cols="12" class="text-center">
                             <qr-pago :qr="qr"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="integrantes.length>0" >
                        <v-col cols="12">
                            <h4>Informacion de Integrantes</h4>
                             <v-simple-table>
                                 <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Fotografia
                                        </th>
                                        <th class="text-left">
                                            Nombre Socio
                                        </th>
                                        <th class="text-left">
                                            Cargo
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="integrante in integrantes" :key="integrante.id">
                                            <td>
                                                <v-avatar
                                                    size="40"
                                                    color="grey"
                                                    class="my-3"
                                                >
                                                    <img :src="`${$docUrl}/${integrante.fotoAbogado}`" >
                                                </v-avatar>
                                            </td>
                                            <td>
                                                {{integrante.nombreAbogado}}
                                            </td>
                                            <td>
                                                <v-icon v-if="integrante.esRepresentante" class="pr-3">mdi-account-check-outline</v-icon>
                                                {{integrante.cargo}}
                                            </td>
                                        </tr>
                                    </tbody>
                                 </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
        
    </v-dialog>
</template>
<script>
import mixinSociedad from "@/mixins/sociedadCivil"
import mixinDateTime from "@/mixins/datetime"

export default {
    props:{
        id:{
            type: Number,
            default: -1
        },
        dialog:{
            type: Boolean,
            default: false
        }
    },
    components:{
        'codigo-pago':()=>import("@/components/Cpt"),
        'qr-pago':()=>import("@/components/QrPago"),
         DescargarArchivo:()=>import("@/components/DescargarArchivo")
    },
    mixins:[mixinSociedad, mixinDateTime],
    data(){
        return{
            loading:true,
            sociedad:null,
            integrantes:[],
            cpt:null
        }
    },
    async created(){
        const {cpt,integrantes,sociedad }= await this.getInfoSociedaCivil(this.id)
        this.sociedad=sociedad
        this.cpt=cpt
        this.integrantes=integrantes
    },
    methods:{
        descargarArchivo(idSociedad){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${idSociedad}/archivo`)
            .then(({data})=>{
                console.log('------------------------------------');
                console.log(data);
                console.log('------------------------------------');
            }).catch(error=>{
                console.log('------------------------------------');
                console.error(error);
                console.log('------------------------------------');
                this.loading=false
            })
        }
    }

}
</script>