export default{
    methods:{
        async getInfoSociedaCivil(id){
                const respuesta = await this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${id}`)
                .catch(error => {
                    this.$notify({
                        group:'foo',
                        type:'error',
                        title:'ERROR',
                        text:error.message
                    })
                    this.loading=false
                    this.$emit('cancelar')
                    this.$emit('cerrar')
                    return {
                        sociedad :null,
                        cpt:null,
                        integrantes : []
                    }
                })
                if(respuesta){
                    this.loading=false
                    const {data} = respuesta
                    return {
                        sociedad :data.data.sociedad,
                        cpt:data.data.cpt,
                        integrantes : data.data.integrantes
                    }
                }
        }
    }
}